import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { getFaq } from 'services/apis'
import { useTitle } from 'utils'

interface IDbFaq {
  id: number
  title: string
  content: string
  order: number
}

export const LearnMorePage = () => {
  useTitle('Learn More', `Discover how Nextres powers investment lending with tailored solutions.`)

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<IDbFaq[]>([])
  const [status, setStatus] = useState<Record<number, boolean>>({})

  useEffect(() => {
    reload()
  }, [])

  const reload = () => {
    setLoading(true)
    getFaq()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  const onToggle = (index: number) => {
    const newStatus = cloneDeep(status)
    newStatus[index] = !newStatus[index]
    setStatus(newStatus)
  }

  return (
    <div className="w-full">
      <div className="content">
        <div className="FundDrawRequest-container">
          <LayoutLoading show={isLoading} />
          <div className="px-5 pb-12 max-w-screen-lg m-auto mt-20">
            <p className="capitalize text-4xl font-semibold text-gray-900 mb-8">Learn More</p>

            {data.map((item, index) => (
              <div className="border mb-6 rounded-lg bg-white" key={item.title}>
                <div
                  className="flex justify-between border-b items-center cursor-pointer p-4"
                  onClick={() => onToggle(index)}
                >
                  <p className="text-lg font-semibold">
                    {index + 1}. {item.title}
                  </p>
                  <ChevronRightIcon
                    className={`w-4 h-4 text-gray-600 transition-all duration-500 transform ${
                      status[index] && 'rotate-90'
                    }`}
                  />
                </div>
                <div
                  className="text-sm max-h-0 overflow-hidden duration-500 transition-all text-gray-600"
                  style={{ maxHeight: status[index] ? '10rem' : '0px' }}
                >
                  <p className="p-4">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
