import { CheckCircleIcon } from '@heroicons/react/24/outline'
import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { submitFundDrawRequest } from 'services/apis'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, RenderInput, useTitle } from 'utils'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    firstname: {
      inputType: 'text0',
      title: 'First Name',
      required: true,
    },
    lastname: {
      inputType: 'text0',
      title: 'Last Name',
      required: true,
    },
    phone: {
      inputType: 'text0',
      type: 'phone',
      title: 'Phone',
      required: true,
    },
    email: {
      inputType: 'text0',
      type: 'email',
      title: 'Email',
      required: true,
    },
    propertyAddress: {
      inputType: 'map',
      title: 'Property Address',
      editable: false,
      required: true,
    },
    amount: {
      inputType: 'text0',
      type: 'thousandSep',
      prefix: '$',
      title: 'Amount to be released',
      required: true,
    },
    description: {
      inputType: 'textarea',
      title: 'Please confirm what work has been completed as part of this draw request',
      required: true,
      span: 'full',
    },
    note: {
      inputType: 'textarea',
      title: 'Note',
      required: false,
      span: 'full',
    },
    provideRoof: {
      inputType: 'CheckBox',
      title: 'Please be sure to provide roof access to the inspector if the roof is to be inspected.',
      required: true,
      span: 'full',
    },
    reimbursementBasis: {
      inputType: 'CheckBox',
      title:
        'I understand that all draws are issued on a reimbursement basis and that I will not receive funds for line items that are not complete.',
      required: true,
      span: 'full',
    },
  }
}

export const FundDrawRequestPage = () => {
  useTitle(`Fund Draw Request`, `Simplify your investment funding process with Nextres' quick draw requests.`)

  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [isSubmitted, setSubmitted] = useState(false)

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      let { inputType, value, error } = newInputs[key]
      if (error) hasError = true
      console.log(inputType, value)
      if (inputType == 'CheckBox' && !value) {
        newInputs[key].error = 'Must be checked'
        hasError = true
      }
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) return false

    setLoading(true)

    submitFundDrawRequest(values)
      .then(() => setSubmitted(true))
      .finally(() => setLoading(false))
  }

  return (
    <div className="w-full">
      <div className="content">
        <div className="FundDrawRequest-container">
          <div className="px-5 pb-12 max-w-screen-lg m-auto mt-20">
            <p className="capitalize text-4xl font-semibold text-gray-900 mb-8">Fund Draw Request</p>
            <div className="grid grid-cols-2 gap-x-4 mb-4 text-gray-700">
              {Object.keys(inputs).map((key) => {
                const input = inputs[key]

                return (
                  <div className={input.span ? `col-span-${input.span}` : ''}>
                    <RenderInput input={input} Key={key} onChange={onChange} />
                  </div>
                )
              })}
            </div>
            {!isSubmitted ? (
              <div className="flex justify-center">
                <Button onClick={onSubmit} loading={isLoading} className="px-16">
                  Submit
                </Button>
              </div>
            ) : (
              <div className="border border-green-500 rounded-lg p-4 bg-green-50 text-green-600 text-center">
                <CheckCircleIcon className="w-12 h-12 text-green-500 mx-auto mb-2" />
                We will reach out back to you as soon as possible.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
