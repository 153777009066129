import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useTitle(title: string, description: string = '') {
  document.title = `${title} - ${process.env.REACT_APP_COMPANY}`
  const metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement
  if (metaDescription) {
    metaDescription.content =
      description || 'Nextres offers personalized investment lending services for your financial growth.'
  }
}

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
